import React from 'react';
// import PropTypes from 'prop-types';
import styles from './linguists.module.scss';

const Linguists = () => (
  <div className={styles.Linguists}>
    Linguists Component
  </div>
);

Linguists.propTypes = {};

Linguists.defaultProps = {};

export default Linguists;

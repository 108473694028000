import React from 'react';
// import PropTypes from 'prop-types';
import styles from './assignments.module.scss';

const UserAssignments = () => (
  <div className={styles.UserAssignments}>
    UserAssignments Component
  </div>
);

UserAssignments.propTypes = {};

UserAssignments.defaultProps = {};

export default UserAssignments;

import React from 'react';
// import PropTypes from 'prop-types';
import styles from './messages.module.scss';

const Messages = () => (
  <div className={styles.Messages}>
    Messages Component
  </div>
);

Messages.propTypes = {};

Messages.defaultProps = {};

export default Messages;

import React from 'react';
// import PropTypes from 'prop-types';
import styles from './log.module.scss';

const UserLog = () => (
  <div className={styles.UserLog}>
    UserLog Component
  </div>
);

UserLog.propTypes = {};

UserLog.defaultProps = {};

export default UserLog;

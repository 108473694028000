import React from 'react';
// import PropTypes from 'prop-types';
import styles from './users.module.scss';

const Users = () => (
  <div className={styles.Users}>
    Users Component
  </div>
);

Users.propTypes = {};

Users.defaultProps = {};

export default Users;

import React from 'react';
// import PropTypes from 'prop-types';
import styles from './email_templates.module.scss';

const EmailTemplates = () => (
  <div className={styles.EmailTemplates}>
    EmailTemplates Component
  </div>
);

EmailTemplates.propTypes = {};

EmailTemplates.defaultProps = {};

export default EmailTemplates;

// import { compact } from 'lodash';
import React from 'react';
import { Routes, Route, Link, redirect } from 'react-router-dom';

import AppLayout from '../components/app';
import Languages from '../components/Languages/Languages';
import UserDashboard from '../pages/user/dashboard/dashboard';
import UserAssignments from '../pages/user/assignments/assignments';
import UserLog from '../pages/user/log/log';
import Pages from '../pages/pages/pages';
import Slides from '../pages/slides/slides';
import Navigation from '../pages/navigation/navigation';
import Accounts from '../pages/accounts/accounts';
import AccountLinks from '../pages/account_links/account_links';
import AccountSchedulerLinks from '../pages/account_scheduler_links/account_scheduler_links';
import Users from '../pages/users/users';
import Linguists from '../pages/linguists/linguists';
import EmailActiveAccounts from '../pages/email_active_accounts/email_active_accounts';
import Messages from '../pages/messages/messages';
import Projects from '../pages/projects/projects';
import Statuses from '../pages/statuses/statuses';
import Positions from '../pages/positions/positions';
import Services from '../pages/services/services';
import BrowseServices from '../pages/browse/services/services';
import Categories from '../pages/categories/categories';
import Residences from '../pages/residences/residences';
import InvoiceIssue from '../pages/invoiceIssue/invoiceIssue';
import Configuration from '../pages/configuration/configuration';
import ReferralTypes from '../pages/referral_types/referral_types';
import EmailTemplates from '../pages/email_templates/email_templates';
import IssueTemplates from '../pages/issue_templates/issue_templates';
import Roles from '../pages/roles/roles';
import ExportEmail from '../pages/export_email/export_email';

const AllRoutes = () => {

  const redirect_Dashboard = redirect("/login");

  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route index element={<Languages />} />
        <Route path = "user" >
          <Route index element={<UserDashboard />} />
          <Route path="dashboard" redirect_Dashboard />
          {/* <Route path="dashboard" element={redirect("/login")} /> */}
          <Route path="assignments" element={<UserAssignments />} />
          <Route path="log" element={<UserLog />} />
        </Route>
        
        <Route path="pages" element={<Pages />} />
        <Route path="slides" element={<Slides />} />
        <Route path="navigation" element={<Navigation />} />
        <Route path="accounts" element={<Accounts />} />
        <Route path="account_links" element={<AccountLinks />} />
        <Route path="account_scheduler_links" element={<AccountSchedulerLinks />} />
        <Route path="users" element={<Users />} />
        <Route path="linguists" element={<Linguists />} />
        <Route path="email_active_accounts" element={<EmailActiveAccounts />} />
        <Route path="messages" element={<Messages />} />
        <Route path="projects" element={<Projects />} />
        <Route path="statuses" element={<Statuses />} />
        <Route path="positions" element={<Positions />} />
        <Route path="services" element={<Services />} />
        <Route path="browse/services" element={<BrowseServices />} />
        <Route path="categories" element={<Categories />} />
        <Route path="residences" element={<Residences />} />
        <Route path="invoiceIssue" element={<InvoiceIssue />} />
        <Route path="configuration" element={<Configuration />} />
        <Route path="languages" element={<Languages />} />
        <Route path="referral_types" element={<ReferralTypes />} />
        <Route path="email_templates" element={<EmailTemplates />} />
        <Route path="issue_templates" element={<IssueTemplates />} />
        <Route path="roles" element={<Roles />} />
        <Route path="export_email" element={<ExportEmail />} />

        {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
};

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default AllRoutes;

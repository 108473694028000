import React from 'react';
// import PropTypes from 'prop-types';
import styles from './positions.module.scss';

const Positions = () => (
  <div className={styles.Positions}>
    Positions Component
  </div>
);

Positions.propTypes = {};

Positions.defaultProps = {};

export default Positions;

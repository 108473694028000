import React from 'react';
// import PropTypes from 'prop-types';
import styles from './slides.module.scss';

const Slides = () => (
  <div className={styles.Slides}>
    Slides Component
  </div>
);

Slides.propTypes = {};

Slides.defaultProps = {};

export default Slides;

import React from 'react';
// import PropTypes from 'prop-types';
import styles from './configuration.module.scss';

const Configuration = () => (
  <div className={styles.Configuration}>
    Configuration Component
  </div>
);

Configuration.propTypes = {};

Configuration.defaultProps = {};

export default Configuration;

import React from 'react';
// import PropTypes from 'prop-types';
import styles from './navigation.module.scss';

const Navigation = () => (
  <div className={styles.Navigation}>
    Navigation Component
  </div>
);

Navigation.propTypes = {};

Navigation.defaultProps = {};

export default Navigation;

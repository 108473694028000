import React from 'react';
// import PropTypes from 'prop-types';
import styles from './projects.module.scss';

const Projects = () => (
  <div className={styles.Projects}>
    Projects Component
  </div>
);

Projects.propTypes = {};

Projects.defaultProps = {};

export default Projects;

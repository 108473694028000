import React from 'react';
// import PropTypes from 'prop-types';
import styles from './dashboard.module.scss';

const UserDashboard = () => (
  <div className={styles.UserDashboard}>
    UserDashboard Component
  </div>
);

UserDashboard.propTypes = {};

UserDashboard.defaultProps = {};

export default UserDashboard;

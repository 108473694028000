import React from 'react';
// import PropTypes from 'prop-types';
import styles from './statuses.module.scss';

const Statuses = () => (
  <div className={styles.Statuses}>
    Statuses Component
  </div>
);

Statuses.propTypes = {};

Statuses.defaultProps = {};

export default Statuses;

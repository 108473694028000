import React from 'react';
// import PropTypes from 'prop-types';
import styles from './roles.module.scss';

const Roles = () => (
  <div className={styles.Roles}>
    Roles Component
  </div>
);

Roles.propTypes = {};

Roles.defaultProps = {};

export default Roles;

import React from 'react';
// import PropTypes from 'prop-types';
import styles from './account_links.module.scss';

const AccountLinks = () => (
  <div className={styles.AccountLinks}>
    AccountLinks Component
  </div>
);

AccountLinks.propTypes = {};

AccountLinks.defaultProps = {};

export default AccountLinks;

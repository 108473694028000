import React from 'react';
// import PropTypes from 'prop-types';
import styles from './residences.module.scss';

const Residences = () => (
  <div className={styles.Residences}>
    Residences Component
  </div>
);

Residences.propTypes = {};

Residences.defaultProps = {};

export default Residences;

import React from 'react';
// import PropTypes from 'prop-types';
import styles from './referral_types.module.scss';

const ReferralTypes = () => (
  <div className={styles.ReferralTypes}>
    ReferralTypes Component
  </div>
);

ReferralTypes.propTypes = {};

ReferralTypes.defaultProps = {};

export default ReferralTypes;

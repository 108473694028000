import React from 'react';
// import PropTypes from 'prop-types';
import styles from './accounts.module.scss';

const Accounts = () => (
  <div className={styles.Accounts}>
    Accounts Component
  </div>
);

Accounts.propTypes = {};

Accounts.defaultProps = {};

export default Accounts;

import React from 'react';
// import PropTypes from 'prop-types';
import styles from './issue_templates.module.scss';

const IssueTemplates = () => (
  <div className={styles.IssueTemplates}>
    IssueTemplates Component
  </div>
);

IssueTemplates.propTypes = {};

IssueTemplates.defaultProps = {};

export default IssueTemplates;

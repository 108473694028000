import React from 'react';
// import PropTypes from 'prop-types';
import styles from './invoiceIssue.module.scss';

const InvoiceIssue = () => (
  <div className={styles.InvoiceIssue}>
    InvoiceIssue Component
  </div>
);

InvoiceIssue.propTypes = {};

InvoiceIssue.defaultProps = {};

export default InvoiceIssue;

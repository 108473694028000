import React from 'react';
// import PropTypes from 'prop-types';
import styles from './pages.module.scss';

const Pages = () => (
  <div className={styles.Pages}>
    Pages Component
  </div>
);

Pages.propTypes = {};

Pages.defaultProps = {};

export default Pages;

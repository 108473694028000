import React from 'react';
// import PropTypes from 'prop-types';
import styles from './export_email.module.scss';

const ExportEmail = () => (
  <div className={styles.ExportEmail}>
    ExportEmail Component
  </div>
);

ExportEmail.propTypes = {};

ExportEmail.defaultProps = {};

export default ExportEmail;

import React from 'react';
// import PropTypes from 'prop-types';
import styles from './services.module.scss';

const Services = () => (
  <div className={styles.Services}>
    Services Component
  </div>
);

Services.propTypes = {};

Services.defaultProps = {};

export default Services;

import React from 'react';
// import PropTypes from 'prop-types';
import styles from './account_scheduler_links.module.scss';

const AccountSchedulerLinks = () => (
  <div className={styles.AccountSchedulerLinks}>
    AccountSchedulerLinks Component
  </div>
);

AccountSchedulerLinks.propTypes = {};

AccountSchedulerLinks.defaultProps = {};

export default AccountSchedulerLinks;

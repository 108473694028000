import React from 'react';
// import PropTypes from 'prop-types';
import styles from './email_active_accounts.module.scss';

const EmailActiveAccounts = () => (
  <div className={styles.EmailActiveAccounts}>
    EmailActiveAccounts Component
  </div>
);

EmailActiveAccounts.propTypes = {};

EmailActiveAccounts.defaultProps = {};

export default EmailActiveAccounts;

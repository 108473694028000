import React from 'react';
// import PropTypes from 'prop-types';
import styles from './services.module.scss';

const BrowseServices = () => (
  <div className={styles.BrowseServices}>
    BrowseServices Component
  </div>
);

BrowseServices.propTypes = {};

BrowseServices.defaultProps = {};

export default BrowseServices;

import {
  AlignJustify,
  Bell,
  Book,
  Calendar as FeCalendar,
  FileMinus,
  Filter as FeFilter,
  Image,
  Inbox,
  Layout,
  List,
  Monitor,
  Settings,
  Users,
} from 'react-feather';


export const MENUITEMS = [
  {
    title: 'Dashboard',
    icon: Layout,
    type: 'link',
    path: '/user/dashboard',
    active: false,
  },
  {
    title: 'Pages',
    icon: Book,
    type: 'link',
    path: '/pages',
    active: false,
  },
  {
    title: 'Slides',
    icon: Image,
    type: 'link',
    path: '/slides',
    active: false,
  },
  {
    title: 'Navigation',
    icon: AlignJustify,
    type: 'link',
    path: '/navigation',
    active: false,
  },
  {
    title: 'Account',
    icon: Monitor,
    type: 'sub',
    active: false,
    children: [
      { path: '/accounts', title: 'Accounts ', type: 'link' },
      { path: '/account_links', title: 'Account Connections ', type: 'link' },
      { path: '/account_scheduler_links', title: 'Scheduler Connections ', type: 'link' },
    ],
  },
  {
    title: 'Users',
    icon: Users,
    type: 'sub',
    active: false,
    children: [
      { path: '/users', title: 'Users', type: 'link' },
      { path: '/linguists', title: 'Linguists ', type: 'link' },
      { path: '/email_active_accounts', title: 'Send Email ', type: 'link' },
    ],
  },
  {
    title: 'My Alerts',
    icon: Bell,
    type: 'link',
    path: '/messages',
    active: false,
  },
  {
    title: 'My Inbox',
    icon: Inbox,
    type: 'link',
    path: '/messages',
    active: false,
  },
  {
    title: 'My Projects',
    icon: FeCalendar,
    type: 'sub',
    active: false,
    children: [
      { path: '/projects', title: 'Projects', type: 'link' },
      { path: '/statuses', title: 'Project Statuses', type: 'link' },
      { path: '/positions', title: 'Positions', type: 'link' },
      { path: '/services', title: 'Manage Services', type: 'link' },
      { path: '/browse/services', title: 'Services', type: 'link' },
      { path: '/categories', title: 'Category', type: 'link' },
      { path: '/residences', title: 'Add Locations', type: 'link' },
    ],
  },
  {
    title: 'My Assignments',
    icon: List,
    type: 'link',
    path: '/user/assignments',
    active: false,
  },
  {
    title: 'Filters',
    icon: FeFilter,
    type: 'sub',
    active: false,
    children: [
      { path: '/projects?_f-projects-status=5d2fd9f58df2ba3b0d8b4588', title: 'Drafts', type: 'link' },
      { path: '/projects?_f-projects-status=532b936b29ce63f9798b456f', title: 'Pending', type: 'link' },
      { path: '/projects?_f-projects-status=532b936b29ce63f9798b456e', title: 'New', type: 'link' },
      { path: '/projects?_f-projects-status=553210398ead0e664d8b4567', title: 'In Progress', type: 'link' },
      { path: '/projects?_f-projects-status=532b936b29ce63f9798b4570', title: 'Complete', type: 'link' },
      { path: '/projects?_f-projects-status=532b936b29ce63f9798b4571', title: 'Cancelled', type: 'link' },
      // TODO insert horzintal bar here
      { path: '/projects?_f-projects-status=5fd285698df2badb6d8b45a0', title: 'pending>HDESD', type: 'link' },
      { path: '/projects?query-projects=&_df-dueDate=&_df-linguist=-&_df-targetLanguage=-&_df-_extendedBy=-&_f-projects-status%5B%5D=532b936b29ce63f9798b456f&_f-projects-status%5B%5D=553210398ead0e664d8b4567&_f-projects-status%5B%5D=554c27e88ead0e9b498b4578&_f-projects-category%5B%5D=5dc06d6d8df2baf54e8b456f&prevLimit-projects=10&limit-projects=10', title: 'Parent-Teacher Conferences', type: 'link' },
    ],
  },
  {
    title: 'Invoices',
    icon: FileMinus,
    type: 'link',
    path: '/invoiceIssue',
    active: false,
  },
  {
    title: 'Settings',
    icon: Settings,
    type: 'sub',
    children: [
      { path: '/configuration', title: 'Configurations', type: 'link' },
      { path: '/languages', title: 'Languages', type: 'link' },
      { path: '/referral_types', title: 'Referral Types for signup', type: 'link' },
      { path: '/email_templates', title: 'Email Templates', type: 'link' },
      { path: '/issue_templates', title: 'Messaging Templates', type: 'link' },
      { path: '/roles', title: 'User Roles', type: 'link' },
      { path: '/export_email', title: 'Export Users', type: 'link' },
    ],
  },
  {
    title: 'User Log (Beta)',
    icon: List,
    type: 'link',
    path: '/user/log',
    active: false,
  },
  
];
